import React from "react"
import { createUseStyles } from "react-jss"
import { colors, MOBILE_BREAKPOINT, sizes } from "../style/design-system"

import SatserWidget from "./satser-widget"
import KalkulatorWidget from "./kalkulator-widget"
import FristerWidget from "./frister-widget"

const useStyles = createUseStyles({
  container: {},
  firstRow: {
    display: "flex",
    marginBottom: sizes["md"],
  },
  firstRowChild: {
    width: `50%`,
    "&:nth-child(2n)": {
      marginLeft: sizes["md"],
    },
  },
  list: {
    minWidth: MOBILE_BREAKPOINT / 3,
  },
  table: {
    width: "100%",
    "& td": {
      border: "none",
      color: colors.gray["600"],
      whiteSpace: `initial`,
    },
  },
  navigation: {
    display: "flex",
    justifyContent: "space-between",
  },
})

const StartPage = () => {
  const classes = useStyles()

  return (
    <div className={classes.container}>
      <div className={classes.firstRow}>
        <SatserWidget className={classes.firstRowChild} />
        <KalkulatorWidget className={classes.firstRowChild}></KalkulatorWidget>
      </div>
      <div className={classes.secondRow}>
        <FristerWidget></FristerWidget>
      </div>
    </div>
  )
}

export default StartPage
