import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { createUseStyles, useTheme } from "react-jss"
import ReactCalendar from "react-calendar"
import { compareDates } from "../../../utils/index"
import CalendarIcon from "./CalendarIcon"

import {
  sizes,
  colors,
  borderRadius,
  boxShadow,
} from "../../style/design-system"

const useStyles = createUseStyles(theme => ({
  outerContainer: {
    display: `flex`,
  },
  button: {
    backgroundColor: "transparent",
    color: colors.gray["600"],
    border: "none",
    fontSize: "inherit",
    fontFamily: "inherit",
    cursor: "pointer",
    outline: "none",
    display: "flex",
    alignItems: "center",
    padding: "0",
    "&:hover": {
      color: colors.blue["500"],
      "& div": {
        backgroundColor: "#F0F0F0",
      },
    },
  },
  content: {
    display: "flex",
    alignItems: "center",
    borderBottom: "1px solid",
  },
  container: {
    /* width: sizes */
    position: "absolute",
    left: "50%",
    transform: "translate(-50%,0)",
    zIndex: "9",
  },
  calendar: {
    borderRadius: borderRadius["sm"],
    boxShadow: boxShadow["lg"],
    width: sizes["8xl"],
    "& abbr": {
      textDecoration: "none",
    },
    "& .react-calendar__navigation": {
      marginBottom: "0",
    },
  },
  nativeDatePicker: {
    marginRight: 5,
    "&::-webkit-inner-spin-button": {
      display: `none`,
    },
    "&::-webkit-clear-button": {
      display: `none`,
    },
  },
  calendarIcon: {
    borderRadius: "3rem",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "1.5em",
    width: "1.5em",
  },
}))

const Calendar = ({ date, setDate, showToday, buttonStyle, useIcon }) => {
  const minDate = `2018-01-01`
  const maxDate = new Date().toJSON().substring(0, 10)
  const [visible, setVisible] = useState(false)
  const [isDateSupported, setIsDateSupported] = useState(false)
  const [inputValue, setInputValue] = useState(
    date.toJSON() && date.toJSON().substring(0, 10)
  )
  const classes = useStyles()
  const theme = useTheme()
  const ref = useRef(0)
  const today = new Date()

  useLayoutEffect(() => {
    if (typeof window !== "undefined") {
      let input = document.createElement(`input`)
      let value = `a`
      input.setAttribute(`type`, `date`)
      input.setAttribute(`value`, value)
      setIsDateSupported(input.value !== value)
    }
  }, [])

  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      setVisible(false)
    }
  }

  useEffect(() => {
    if (visible) document.addEventListener("click", handleClick)
    return () => document.removeEventListener("click", handleClick)
  }, [visible])

  const handleChange = value => {
    setDate(value)
    setVisible(false)
  }

  const handleDateChange = e => {
    let inputDate = new Date(e.target.value)
    if (inputDate <= today) {
      setInputValue(e.target.value)
      setDate(new Date(e.target.value))
    }
  }

  let buttonValue = `${date.getDate()}/${date.getMonth() +
    1}/${date.getFullYear()}`
  if (showToday && compareDates(date, today)) buttonValue = "I dag"

  return (
    <div className={classes.outerContainer} ref={ref}>
      {isDateSupported && (
        <input
          type="date"
          className={classes.nativeDatePicker}
          value={inputValue}
          min={minDate}
          max={maxDate}
          onChange={handleDateChange}
        ></input>
      )}
      {!isDateSupported && (
        <button
          className={
            buttonStyle ? `${classes.button} ${buttonStyle}` : classes.button
          }
          type="button"
          onClick={() => setVisible(!visible)}
        >
          <span className={classes.content}>{buttonValue}</span>
          {useIcon && (
            <div className={classes.calendarIcon}>
              <CalendarIcon
                color={colors.blue["500"]}
                height="1rem"
                width="1rem"
              />
            </div>
          )}
        </button>
      )}
      {visible && !isDateSupported && (
        <div className={classes.container}>
          <ReactCalendar
            className={classes.calendar}
            activeStartDate={date}
            onChange={handleChange}
            maxDate={today}
            minDate={new Date(1999, 0, 4)}
            minDetail="month"
          />
        </div>
      )}
    </div>
  )
}

Calendar.defaultProps = {
  useIcon: true,
}

export default Calendar
