import React from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"

import {
  sizes,
  colors,
  fontSizes,
  borders,
  borderRadius,
  MOBILE_BREAKPOINT,
} from "../style/design-system"

const useStyles = createUseStyles({
  container: {
    borderRadius: borderRadius["sm"],
    display: `inline-block`,
    marginBottom: sizes["lg"],
    overflow: `auto`,
    width: `100%`,
  },
  line: {
    display: `flex`,
    alignItems: `baseline`,
    marginBottom: sizes["xs"],
    "&:last-of-type": {
      marginBottom: 0,
    },
    "&:first-of-type": {
      borderTop: borders["contentRegular"],
      paddingTop: sizes["xs"],
    },
    borderBottom: borders["contentRegular"],
    paddingBottom: sizes["sm"],
  },
  lineTitle: {
    margin: 0,
    marginRight: sizes["lg"],
    width: 55,
    fontWeight: 700,
  },
  lineContent: {
    width: `100%`,
  },
  regularContent: {
    display: `flex`,
    justifyContent: `space-between`,
    marginBottom: sizes["xs"],
    alignItems: `baseline`,
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  accountNumberStyles: {
    textDecoration: `dashed`,
    width: 55,
    display: `inline-block`,
  },
  textContent: {
    marginRight: sizes["lg"],
  },
  debetCreditContent: {
    display: `inline-block`,
    fontSize: fontSizes["sm"],
    marginLeft: sizes["xs"],
  },
  lineParamsContent: {
    marginBottom: 0,
    marginTop: sizes["xxs"],
    display: `flex`,
    justifyContent: `space-between`,
    "& > *": {
      display: `inline-block`,
      marginRight: sizes["lg"],
    },
    "& > *:last-of-type": {
      marginRight: 0,
    },
  },
  strike: {
    textDecoration: `line-through`,
  },
  [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
    textContent: {
      marginRight: 0,
    },
    lineParamsContent: {
      flexWrap: `wrap`,
    },
    accountNumberStyles: {
      display: `initial`,
    },
  },
})

const Lines = ({ bitParams, lines, hitwords, renderForEmail }) => {
  const {
    container,
    line,
    lineTitle,
    lineContent,
    regularContent,
    accountNumberStyles,
    textContent,
    debetCreditContent,
    lineParamsContent,
    strike,
  } = useStyles()

  lines.forEach(line => {
    if (line.name === `L?nn`) line.name = `Lønn`
  })

  let linesObject = lines.reduce((accumulator, current) => {
    const { name, account, accounttitle, debet_kredit } = current
    if (!accounttitle || !name) return accumulator
    if (!accumulator) accumulator = {}
    if (!accumulator[name]) accumulator[name] = []

    let accountNumber, text
    if (name === `MVA` && accounttitle.includes(` - `)) {
      ;[accountNumber, text] = accounttitle.split(` - `)
      text = text.charAt(0).toUpperCase() + text.slice(1)
    } else {
      ;[accountNumber, ...text] = accounttitle.split(` `)
      text = text.join(` `)
    }

    let debetCredit
    debet_kredit === `1`
      ? (debetCredit = `Debet`)
      : debet_kredit === `2`
      ? (debetCredit = `Kredit`)
      : (debetCredit = ``)

    accumulator[name].push({
      type: `regular`,
      accountNumber,
      text,
      debetCredit,
    })

    return accumulator
  }, null)

  let bitParamsLine
  if (bitParams !== ``) {
    let bitString = bitParams ? parseInt(bitParams, 10).toString(2) : `00000`
    let remainingZeroes = 5 - bitString.length

    let bitArray
    remainingZeroes
      ? (bitArray = [
          ...new Array(remainingZeroes).fill(`0`),
          ...bitString.split(``),
        ])
      : (bitArray = bitString.split(``))

    let bitParamsLineUnordered = [
      `Feriepenger`,
      `Skatteplikt`,
      `Trekkplikt`,
      `AGA-plikt`,
      `Opplysningsplikt`,
    ].map((name, index) => ({ name, value: parseInt(bitArray[index], 10) }))

    bitParamsLine = [
      ...bitParamsLineUnordered.slice(1),
      bitParamsLineUnordered[0],
    ]
  }

  if (bitParamsLine) {
    !linesObject
      ? (linesObject = { Lønn: [{ type: `lineParams`, bitParamsLine }] })
      : !linesObject[`Lønn`]
      ? (linesObject[`Lønn`] = [{ type: `lineParams`, bitParamsLine }])
      : linesObject[`Lønn`].push({ type: `lineParams`, bitParamsLine })
  }

  let ret =
    linesObject && renderForEmail ? (
      <table
        cellPadding="0"
        cellSpacing="0"
        width="100%"
        border="0"
        style={{
          color: `#000000`,
          fontFamily: `Ubuntu, Helvetica, Arial, sans-serif`,
          fontSize: 13,
          lineHeight: `22px`,
          tableLayout: `auto`,
          width: `100%`,
          border: `none`,
          marginBottom: 20,
        }}
      >
        {[`MVA`, `Konto`, `Lønn`].map(
          (name, i1) =>
            linesObject[name] &&
            linesObject[name].map((item, i2) =>
              item.type === `regular` ? (
                <tr
                  key={i2}
                  style={
                    linesObject[name].length === i2 + 1
                      ? { borderBottom: `1px solid #ecedee` }
                      : {}
                  }
                >
                  <th> {i2 === 0 ? name : ``} </th>
                  <td>{item.accountNumber}</td>
                  <td>{item.text}</td>
                  <td>{item.debetCredit ? item.debetCredit : ``}</td>
                </tr>
              ) : (
                <tr
                  key={i2}
                  style={
                    linesObject[name].length === i2 + 1
                      ? { borderBottom: `1px solid #ecedee` }
                      : {}
                  }
                >
                  <th></th>
                  <td colSpan="3">
                    {item.bitParamsLine.map((content, i3) => (
                      <span
                        key={i3}
                        style={
                          content.value
                            ? { marginRight: 4 }
                            : {
                                textDecoration: `line-through`,
                                marginRight: 12,
                              }
                        }
                      >
                        {content.name}
                      </span>
                    ))}
                  </td>
                </tr>
              )
            )
        )}
      </table>
    ) : linesObject && !renderForEmail ? (
      <div className={container}>
        {[`MVA`, `Konto`, `Lønn`].map((name, i) =>
          linesObject[name] ? (
            <div className={line} key={i}>
              <span className={lineTitle}>{name}</span>
              <div className={lineContent}>
                {linesObject[name].map((item, i) =>
                  item.type === `regular` ? (
                    <div className={regularContent} key={i}>
                      <div>
                        <span className={accountNumberStyles}>
                          {item.accountNumber}{" "}
                        </span>
                        <span className={textContent}>{item.text}</span>
                      </div>
                      {item.debetCredit ? (
                        <span className={debetCreditContent}>
                          {item.debetCredit}
                        </span>
                      ) : null}
                    </div>
                  ) : (
                    <div className={lineParamsContent} key={i}>
                      {item.bitParamsLine.map((content, i) => (
                        <span className={content.value ? `` : strike} key={i}>
                          {content.name}
                        </span>
                      ))}
                    </div>
                  )
                )}
              </div>
            </div>
          ) : null
        )}
      </div>
    ) : null

  return ret
}

const mapStateToProps = state => {
  return {
    renderForEmail: state.renderForEmail,
  }
}

export default connect(mapStateToProps, null)(Lines)
