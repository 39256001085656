import React from "react"

export default ({ className, color, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={className}
  >
    <title>
      Exported from Streamline App (https://app.streamlineicons.com)
    </title>
    <g transform="matrix(1.6666666666666667,0,0,1.6666666666666667,0,0)">
      <path
        d="M 16.004,0.5c4.142,0,7.5,3.358,7.5,7.5s-3.358,7.5-7.5,7.5s-7.5-3.358-7.5-7.5S11.862,0.5,16.004,0.5z "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 8.53,8.526c-4.132-0.294-7.719,2.817-8.013,6.949s2.817,7.719,6.949,8.013c4.132,0.294,7.719-2.817,8.013-6.949 c0.025-0.355,0.025-0.711,0-1.065 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 0.985,5.035l2.006,1.993l1.994-2.005 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 7.973,1.013C5.212,1.021,2.98,3.267,2.988,6.028 c0,0,0,0,0,0v1 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 23.023,18.965l-2.006-1.993l-1.994,2.005 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 16.035,22.987c2.761-0.008,4.993-2.254,4.985-5.015c0,0,0,0,0,0v-1 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 8.5,19.5c-1.485,0.172-2.828-0.893-3-2.378c-0.024-0.207-0.024-0.415,0-0.622c-0.172-1.485,0.893-2.828,2.378-3 c0.207-0.024,0.415-0.024,0.622,0 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.504,15.5h4 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.504,17.5h3 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 17.5,4.5h-1.7c-0.718-0.006-1.304,0.572-1.31,1.29 c-0.003,0.442,0.218,0.855,0.587,1.097l1.84,1.226c0.6,0.394,0.768,1.2,0.374,1.8c-0.241,0.367-0.651,0.588-1.091,0.587h-1.7 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 16.504,4.5v-1 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 15.504,11.5v-1"
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
)
