import React from "react"
// import { Link } from "gatsby"
import { Link as RLink } from "@reach/router"
import { createUseStyles } from "react-jss"
import ChevronRight from "./chevron-right"
import CarIcon from "./car-icon"
import CurrencyIcon from "./currency-icon"
import CalculatorIcon from "./calculator-icon"

import {
  colors,
  colorAliases,
  borders,
  borderRadius,
  sizes,
  MOBILE_BREAKPOINT,
} from "../style/design-system"

const kalkulatorer = [
  {
    name: "Bompengekalkulator",
    url: "https://bompengekalkulator.no",
    icon: CarIcon,
  },
  { name: "Valutakalkulator", path: "/valutakalkulator", icon: CurrencyIcon },
  { name: "MVA-Kalkulator", path: "/mva-kalkulator", icon: CalculatorIcon },
]

const useStyles = createUseStyles({
  container: {
    border: borders["regularBold"],
    borderRadius: borderRadius["lg"],
  },
  header: {
    display: `flex`,
    alignItems: `baseline`,
    justifyContent: `space-between`,
    padding: sizes["md"],
    borderBottom: borders["regularBold"],
  },
  linkStyle: {
    display: `inline-block`,
    width: `100%`,
    marginBottom: sizes["xs"],
    "&:last-of-type": {
      marginBottom: 0,
    },
  },
  button: {
    display: `flex`,
    border: borders["regular"],
    borderRadius: borderRadius["lg"],
  },
  iconContainer: {
    padding: sizes["sm"],
    borderRight: borders["regular"],
  },
  icon: {
    width: 35,
    height: 35,
  },
  title: {
    margin: 0,
  },
  kalkisTitle: {
    margin: 0,
  },
  buttonText: {
    padding: sizes["sm"],
    display: `flex`,
    alignItems: `center`,
    justifyContent: `space-between`,
    width: `100%`,
  },
  chevronRight: {
    width: 30,
    height: 30,
  },
  body: {
    padding: sizes["md"],
  },
})

export default ({ className }) => {
  const classes = useStyles()

  return (
    <div className={[className, classes.container].join(` `)}>
      <div className={classes.header}>
        <h3 className={classes.title}>Kalkulatorer</h3>
      </div>
      <div className={classes.body}>
        {kalkulatorer.map((kalkis, i) => {
          if (kalkis.url) {
            return (
              <a
                style={{ textDecoration: `none`, color: `inherit` }}
                className={classes.linkStyle}
                href={kalkis.url}
                target="_blank"
                rel="noopener noreferrer"
                data-iconafter="no"
                key={i}
              >
                <div className={classes.button}>
                  <div className={classes.iconContainer}>
                    {React.createElement(kalkis.icon, {
                      className: classes.icon,
                      color: colorAliases["darkColor"],
                    })}
                  </div>
                  <div className={classes.buttonText}>
                    <h4 className={classes.kalkisTitle}>{kalkis.name}</h4>{" "}
                    <ChevronRight
                      className={classes.chevronRight}
                      color={colorAliases["darkColor"]}
                    ></ChevronRight>
                  </div>
                </div>
              </a>
            )
          } else {
            return (
              <RLink
                style={{ textDecoration: `none`, color: `inherit` }}
                className={classes.linkStyle}
                to={kalkis.path}
                key={i}
              >
                <div className={classes.button}>
                  <div className={classes.iconContainer}>
                    {React.createElement(kalkis.icon, {
                      className: classes.icon,
                      color: colorAliases["darkColor"],
                    })}
                  </div>
                  <div className={classes.buttonText}>
                    <h4 className={classes.kalkisTitle}>{kalkis.name}</h4>{" "}
                    <ChevronRight
                      className={classes.chevronRight}
                      color={colorAliases["darkColor"]}
                    ></ChevronRight>
                  </div>
                </div>
              </RLink>
            )
          }
        })}
      </div>
    </div>
  )
}
