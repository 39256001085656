import React from "react"
import { createUseStyles } from "react-jss"

import { sizes, colors } from "../../style/design-system"

const useStyles = createUseStyles({
  input: {
    width: "100%",
    height: 40,
    textAlign: "right",
    color: colors.gray["600"],
    padding: sizes["sm"],
    backgroundColor: "transparent",
    border: `none`,
    appearance: "none",
    "&::placeholder": {
      textDecoration: "underline",
    },
  },
})

const Input = ({ value, setValue }) => {
  const classes = useStyles()

  return (
    <input
      className={classes.input}
      value={parseFloat(value) > 0 ? value : ``}
      onChange={e => setValue(e.target.value)}
    />
  )
}

export default Input
