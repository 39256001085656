import React from "react"
import { createUseStyles } from "react-jss"
import Input from "./Input"
import ArrowDown from "./ArrowDown"

import { sizes, colors, fontSizes, boxShadow } from "../../style/design-system"

const useStyles = createUseStyles({
  root: {
    margin: sizes["xxs"],
    width: "100%",
    height: 41,
    position: "relative",
    display: `flex`,
    boxShadow: boxShadow["md"],
    border: `1px solid ${colors.blue["500"]}`,
    "@media print": {
      border: `none`,
    },
  },
  selectContainer: {
    position: `relative`,
  },
  label: {
    position: `absolute`,
    top: 0,
    bottom: 0,
    left: 0,
    width: 86,
    background: colors["blue"]["500"],
    fontSize: fontSizes["lg"],
    pointerEvents: `none`,
    display: `flex`,
    alignItems: `center`,
    justifyContent: `center`,
    color: `white`,
    "@media print": {
      color: `inherit`,
      top: 4,
      bottom: 4,
      left: 10,
    },
  },
  select: {
    width: 86,
    padding: `0 ${sizes["md"]}`,
    fontSize: fontSizes["lg"],
    borderRadius: 0,
    border: `none`,
    height: 40,
    "-webkit-appearance": `none`,
    "-moz-appearance": `none`,
    appearance: `none`,
    background: colors["blue"]["500"],
    color: `white`,
    "&::-ms-expand": {
      display: `none`,
    },
    cursor: `pointer`,
  },
})

const CurrencyBox = React.forwardRef((props, forwardedRef) => {
  const { amount, setAmount, symbol, setSymbol, symbolData } = props
  const classes = useStyles()

  const handleSelect = e => {
    setSymbol(e.target.value)
  }

  return (
    <div className={classes.root}>
      <div className={classes.selectContainer}>
        <div className={classes.label}>
          <span>{symbol}</span> <ArrowDown color="white" />
        </div>
        <select
          className={classes.select}
          ref={forwardedRef}
          value={symbol}
          onChange={handleSelect}
        >
          <optgroup>
            {symbolData.map(
              (item, i) =>
                item.important && (
                  <option value={item.symbol} key={i} label={item.value}>
                    {item.symbol} - {item.name}
                  </option>
                )
            )}
          </optgroup>
          <optgroup>
            {symbolData.map(
              (item, i) =>
                !item.important && (
                  <option value={item.symbol} key={i} label={item.value}>
                    {item.symbol} - {item.name}
                  </option>
                )
            )}
          </optgroup>
        </select>
      </div>

      <Input value={amount} setValue={setAmount} />
    </div>
  )
})

export default CurrencyBox
