import React from "react";
import PropTypes from "prop-types";
import {createUseStyles} from "react-jss";

import {
    sizes,
    colors,
    fontSizes,
    fonts,
    borderRadius,
    MOBILE_BREAKPOINT,
  } from "../../style/design-system"

const useStyles = createUseStyles({
    root: {
        width: "100%",
        marginBottom: sizes["xl"]
    },
    header: {
        color: colors.gray["600"],
        fontSize: props => props.fontSize ? props.fontSize : "2.2rem",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginTop: "0",
    },
    subHeader: {
        marginTop: sizes["xs"],
        fontSize: fontSizes["sm"],
        fontWeight: "400",
        textAlign: "center",
    }
});

const Header = props => {
    const {children, subHeader} = props;
    const classes = useStyles(props);

    return (
        <div className={classes.root}>
            <h2 className={classes.header}>{children}</h2>
            <h3 className={classes.subHeader}>{subHeader}</h3>
        </div>
    );
};

Header.propTypes = {
    children: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.element,
        PropTypes.arrayOf(PropTypes.element)
    ]),
};

export default Header;
