import React from "react"
import { createUseStyles } from "react-jss"

import UnfoldIcon from "./unfold-icon"
import ArrowUp from "../valutakalkulator/ArrowUp"
import ArrowDown from "../valutakalkulator/ArrowDown"

import { colors, sizes, fontSizes } from "../../style/design-system"

const items = [
  { name: "25%", value: 25 },
  { name: "15%", value: 15 },
  { name: "12%", value: 12 },
  { name: "6%", value: 6 },
]

const useStyles = createUseStyles({
  container: {
    position: `relative`,
  },
  select: {
    width: 86,
    padding: `0 ${sizes["md"]}`,
    fontSize: fontSizes["lg"],
    borderRadius: 0,
    border: `none`,
    height: 40,
    "-webkit-appearance": `none`,
    "-moz-appearance": `none`,
    appearance: `none`,
    background: colors["blue"]["500"],
    color: `white`,
    "&::-ms-expand": {
      display: `none`,
    },
    cursor: `pointer`,
  },
  icon: {
    position: `absolute`,
    width: 24,
    right: 9,
    top: 8,
    pointerEvents: `none`,
  },
})

const Select = ({ value, onChange }) => {
  const classes = useStyles()

  const handleChange = e => {
    onChange(parseInt(e.target.value))
  }

  return (
    <div className={classes.container}>
      <select className={classes.select} value={value} onChange={handleChange}>
        {items.map((item, i) => (
          <option value={item.value} key={i}>
            {item.name}
          </option>
        ))}
      </select>

      {value === items[0].value ? (
        <ArrowDown className={classes.icon} color="white"></ArrowDown>
      ) : value === items[items.length - 1].value ? (
        <ArrowUp className={classes.icon} color="white"></ArrowUp>
      ) : (
        <UnfoldIcon className={classes.icon} color="white"></UnfoldIcon>
      )}
    </div>
  )
}

export default Select
