import React, { useState, useEffect, useRef, useLayoutEffect } from "react"
import { createUseStyles } from "react-jss"
import CurrencyBox from "./CurrencyBox"
import Calendar from "./Calendar"
import RateInfo from "./RateInfo"
import Info from "./Info"
import { useExchangeRates } from "../../hooks/valutakalkulator"

import { sizes, colors } from "../../style/design-system"

const symbolData = [
  { symbol: "NOK", name: "Norske kroner", important: true },
  { symbol: "EUR", name: "Euro", important: true },
  { symbol: "USD", name: "Amerikanske dollar", important: true },
  { symbol: "CAD", name: "Kanadisk dollar", important: false },
  { symbol: "HKD", name: "Hong Kong dollar", important: false },
  { symbol: "ISK", name: "Islandske kroner", important: false },
  { symbol: "PHP", name: "Filippinske peso", important: false },
  { symbol: "DKK", name: "Danske kroner", important: false },
  { symbol: "HUF", name: "Ungarske forint", important: false },
  { symbol: "CZK", name: "Tjekkiske koruna", important: false },
  { symbol: "GBP", name: "Britiske pund", important: true },
  { symbol: "RON", name: "Rumenske leu", important: false },
  { symbol: "SEK", name: "Svenske kroner", important: true },
  { symbol: "IDR", name: "Indonesiske rupiah", important: false },
  { symbol: "INR", name: "Indiske rupi", important: false },
  { symbol: "BRL", name: "Brasilianske real", important: false },
  { symbol: "RUB", name: "Russiske rubel", important: false },
  { symbol: "HRK", name: "Kroatiske kuna", important: false },
  { symbol: "JPY", name: "Japanske yen", important: false },
  { symbol: "THB", name: "Thailandske baht", important: false },
  { symbol: "CHF", name: "Sveitsiske franc", important: false },
  { symbol: "MYR", name: "Malaysiske ringgit", important: false },
  { symbol: "BGN", name: "Bulgarske lev", important: false },
  { symbol: "TRY", name: "Tyrkiske lira", important: false },
  { symbol: "CNY", name: "Kinesiske yuan", important: false },
  { symbol: "NZD", name: "New Zealandske dollar", important: false },
  { symbol: "ZAR", name: "Sør-Afrikanske rand", important: false },
  { symbol: "MXN", name: "Meksikanske peso", important: false },
  { symbol: "SGD", name: "Singaporske dollar", important: false },
  { symbol: "AUD", name: "Australske dollar", important: false },
  { symbol: "ILS", name: "Israelske shekel", important: false },
  { symbol: "KRW", name: "Sør-Koreanske won", important: false },
  { symbol: "PLN", name: "Polske zloty", important: false },
]

const defaultValues = {
  amount: 0,
  base: "EUR",
  symbol: "NOK",
}

const useStyles = createUseStyles({
  root: {
    width: "100%",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    marginBottom: "1vh",
  },
  row: {
    display: "flex",
    alignItems: "center",
    width: "100%",
    padding: sizes["sm"],
    justifyContent: "center",
  },
  calendar: {
    marginLeft: "0.4rem",
    display: "flex",
    justifyContent: "center",
  },
  text: {
    display: "flex",
    justifyContent: "center",
    color: colors.gray["600"],
  },
  result: {
    width: "10rem",
    textAlign: "right",
    fontWeight: "500",
  },
})

const Calculator = () => {
  const classes = useStyles()
  const allButNumbersCommaDot = /[^\d,.]/g

  const [symbol, setSymbol] = useState(defaultValues.symbol)
  const [symbolAmount, setSymbolAmount] = useState(0)
  const [baseAmount, setBaseAmount] = useState(defaultValues.amount)

  const { base, setBase, rates, date, setDate } = useExchangeRates(
    defaultValues.base,
    symbolData
  )

  const checkDecimals = v =>
    !v.includes(".") || (v.includes(".") && v.split(".")[1].length < 3)

  const round2 = num => Math.round((num + Number.EPSILON) * 100) / 100

  const numberIsh = amount => {
    if (typeof amount === `string` && amount.includes(`,`)) {
      return amount.replace(/,/g, ".")
    } else {
      return amount
    }
  }

  useEffect(() => {
    if (rates && Object.entries(rates).length) {
      let convertedBase = numberIsh(baseAmount)

      setSymbolAmount(round2(rates[symbol] * convertedBase))
    }
  }, [rates, symbol]) // eslint-disable-line

  const handleBaseAmountChange = amount => {
    const rawAmount = amount
    const val = amount.replace(allButNumbersCommaDot, "").replace(/,/g, ".")

    if (
      (typeof parseFloat(val) === "number" || val === "") &&
      (val.match(/\./g) || []).length < 2 && // ikke mer enn 1 punktum
      checkDecimals(val) // se oppe
    ) {
      setBaseAmount(rawAmount.replace(allButNumbersCommaDot, ""))
      setSymbolAmount(round2(val * rates[symbol]))
    }
  }

  const handleSymbolAmountChange = amount => {
    const rawAmount = amount
    const val = amount.replace(allButNumbersCommaDot, "").replace(/,/g, ".")

    if (
      (typeof parseFloat(val) === "number" || val === "") &&
      (val.match(/\./g) || []).length < 2 && // ikke mer enn 1 punktum
      checkDecimals(val) // se oppe
    ) {
      setSymbolAmount(rawAmount.replace(allButNumbersCommaDot, ""))
      setBaseAmount(round2(val / rates[symbol]))
    }
  }

  const focusRef = useRef()
  useLayoutEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.focus()
    }
  }, [focusRef, focusRef.current])

  return (
    <div className={classes.root}>
      <CurrencyBox
        symbolData={symbolData}
        amount={baseAmount}
        setAmount={handleBaseAmountChange}
        symbol={base}
        setSymbol={setBase}
        ref={focusRef}
      />
      <div className={classes.row}>
        <div className={classes.text}>den</div>
        <div className={classes.calendar}>
          <Calendar date={date} setDate={setDate} useNative />{" "}
        </div>
        <div className={classes.text}>er</div>
      </div>
      <CurrencyBox
        symbolData={symbolData}
        amount={symbolAmount}
        setAmount={handleSymbolAmountChange}
        symbol={symbol}
        setSymbol={setSymbol}
      />
      {rates && <RateInfo rate={base === symbol ? 1.0 : rates[symbol]} />}
      <Info
        base={base}
        baseAmount={baseAmount}
        symbol={symbol}
        symbolAmount={symbolAmount}
        date={date}
        symbolData={symbolData}
        numberIsh={numberIsh}
        round2={round2}
      />
    </div>
  )
}

export default Calculator
