import React from "react"
import { createUseStyles } from "react-jss"

import { sizes, colors } from "../../style/design-system"

const useStyles = createUseStyles({
  input: {
    width: "100%",
    height: "100%",
    textAlign: "right",
    border: "none",
    color: colors.gray["600"],
    padding: sizes["sm"],
    backgroundColor: "transparent",
    appearance: "none",
    "&::placeholder": {
      textDecoration: "underline",
    },
  },
})

const Input = React.forwardRef(
  ({ placeholder, value, onChange, disabled = false }, ref) => {
    const classes = useStyles()

    const isNumber = arg => typeof arg === "number"

    const handleChange = e => {
      const val = e.target.value.replace(/[^\d,*]/g, "").replace(/,/g, ".")
      const checkDecimals = v =>
        !v.includes(".") || (v.includes(".") && v.split(".")[1].length < 3)

      if (
        (isNumber(parseFloat(val)) || val === "") &&
        (val.match(/\./g) || []).length < 2 &&
        checkDecimals(val)
      ) {
        let num = parseFloat(val)
        if (isNaN(num)) num = 0
        onChange(num)
      }
    }

    const formatNumber = val => {
      const formatedNumber = Intl.NumberFormat("nb-NO", {
        maximumFractionDigits: 2,
      }).format(val)

      let tail = val.toString().slice(-1)
      if (tail === ".") {
        tail = ","
      } else {
        tail = val.toString().slice(-2)
        if (tail === ".0") {
          tail = ",0"
        } else {
          tail = val.toString().slice(-3)
          if (tail === ".00") {
            tail = ",00"
          } else {
            tail = ""
          }
        }
      }
      return `${formatedNumber}${tail}`
    }

    return (
      <input
        className={classes.input}
        placeholder={placeholder}
        value={value > 0 ? formatNumber(value) : ``}
        onChange={handleChange}
        disabled={disabled}
        ref={ref}
      />
    )
  }
)

export default Input
