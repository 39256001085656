import React from "react"

export default ({ className, color, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={className}
  >
    <g transform="matrix(1.6666666666666667,0,0,1.6666666666666667,0,0)">
      <path
        d="M 10.003,1.499h0.998 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 12.999,1.499h0.998 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 15.994,1.499h0.999 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 18.99,1.5h1c0.552,0,1,0.448,1,1v1 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 20.987,5.493v0.999 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 20.987,8.489v0.999 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 8.505,4.494c0,2.5-3.994,5.992-3.994,5.992S0.517,6.991,0.517,4.494 c0-2.206,1.788-3.994,3.994-3.994S8.505,2.288,8.505,4.494z "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.511,4.245c0.138,0,0.249,0.111,0.249,0.249l0,0 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.261,4.494 c0.001-0.138,0.112-0.249,0.25-0.249 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.511,4.744c-0.138,0-0.25-0.112-0.25-0.25 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.76,4.494c0.001,0.138-0.11,0.249-0.248,0.25 c0,0-0.001,0-0.001,0 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 20.058,19.996c0.968,0,1.752,0.784,1.752,1.752S21.026,23.5,20.058,23.5s-1.752-0.784-1.752-1.752 S19.09,19.996,20.058,19.996z "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 6.922,19.996c0.968,0,1.752,0.784,1.752,1.752S7.89,23.5,6.922,23.5S5.17,22.716,5.17,21.748 S5.954,19.996,6.922,19.996z "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 17.953,17.609c-0.458,1.469-1.208,2.863-3.457,2.863H11.75c-2.114,0-1.724-6.491,2.086-6.491h1.914 C17.492,13.981,18.745,15.069,17.953,17.609z "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 17.991,17.476h-7.489 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 5.327,22.47H5.09c-0.872,0-1.578-0.707-1.578-1.578 c0-0.086,0.007-0.171,0.021-0.256c0.8-4.9,5.744-8.676,10.833-8.676c5.052,0,8.513,3.721,9.105,8.571 c0.115,0.948-0.56,1.811-1.508,1.926c-0.069,0.008-0.138,0.013-0.208,0.013h-0.1 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 16.494,22.47h-5.992 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.683,17.476 c1.472,0,3,0.186,3.434-0.946c0.463-1.224,1.204-2.323,2.165-3.21l1.131-0.943 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 13,12.05v-1.065 c0.048-0.827,0.757-1.459,1.584-1.411c0.76,0.044,1.367,0.651,1.411,1.411V12.1"
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
)
