import React from "react"

export default ({ className, color, onClick }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 40 40"
    className={className}
  >
    <g transform="matrix(1.6666666666666667,0,0,1.6666666666666667,0,0)">
      <path
        d="M 8.004,19l-4-4 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.004,19l4-4 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 6.004,4.5v5 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 8.504,7h-5 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 15.504,7h5 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 15.504,18h5 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 15.504,18h5 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 15.504,16h5 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 12.004,1v22 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 1.004,12h22 "
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
      <path
        d="M 4.004,1h16c1.657,0,3,1.343,3,3v16c0,1.657-1.343,3-3,3h-16c-1.657,0-3-1.343-3-3V4 C1.004,2.343,2.347,1,4.004,1z"
        stroke={color}
        fill="none"
        strokeWidth="1"
        strokeLinecap="round"
        strokeLinejoin="round"
      ></path>
    </g>
  </svg>
)
