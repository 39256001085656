import React, { useEffect } from "react"
import { connect } from "react-redux"
import { graphql } from "gatsby"

import SEO from "../components/seo"
import ContentList from "../components/content-list"
import StartPage from "../components/start-page"
import Valutakalkulator from "../components/valutakalkulator/Valutakalkulator"
import MvaCalculator from "../components/mva-kalkulator"

import { hitblockSetCurrent } from "../state/actions/hitblock"

const Page = ({ data, pageContext, setCurrentHitblock }) => {
  const contentList = []
  let keywords = []
  let description = ``
  const textFinder = node => {
    if (node.type === `text`) return node.text

    if (node.children) {
      return node.children.reduce((accumulator, current) => {
        return accumulator + textFinder(current)
      }, ``)
    }

    return ``
  }

  const paragraphFinder = js => {
    let p
    if (js) {
      p = js.find(item => item.type === `element` && item.name === `p`)
      if (p) return p

      for (let child of js) {
        if (child.children) p = paragraphFinder(child.children)
        if (p) break
      }
    }

    return p
  }

  data.allHitsJson.edges.forEach(({ node }) => {
    let js = JSON.parse(node.json).children[0].children[0].children[0].children
    node.js = js
    contentList.push(node)

    if (pageContext.openInitial === node.title) {
      keywords = node.tags.split(`|`).map(word => word.trim())
      let descriptionParagraph = paragraphFinder(js)
      description = descriptionParagraph ? textFinder(descriptionParagraph) : ``
    }
  })

  if (keywords.length === 0) keywords = [`dib`, `kontohjelp`, `regnskap`]

  useEffect(() => {
    setCurrentHitblock(pageContext.hitblock)
  }, [pageContext.hitblock])

  return (
    <>
      <SEO
        title={
          pageContext.category === `mva-kalkulator`
            ? `MVA-Kalkulator - Kontohjelp`
            : pageContext.category === `valutakalkulator`
            ? `Valutakalkulator - Kontohjelp`
            : !contentList.length
            ? `Kontohjelp`
            : pageContext.openInitial !== `none`
            ? `${pageContext.hitblock} - ${pageContext.openInitial} - Kontohjelp`
            : `${pageContext.hitblock} - Kontohjelp`
        }
        lang="nb"
        keywords={keywords}
        description={description}
      />

      {contentList.length ? (
        <ContentList
          contentList={contentList}
          openInitial={pageContext.openInitial}
        />
      ) : pageContext.category === `mva-kalkulator` ? (
        <MvaCalculator></MvaCalculator>
      ) : pageContext.category === `valutakalkulator` ? (
        <Valutakalkulator />
      ) : (
        <StartPage />
      )}
    </>
  )
}

const mapDispatchToProps = dispatch => {
  return {
    setCurrentHitblock: hitblock => dispatch(hitblockSetCurrent(hitblock)),
  }
}

export default connect(null, mapDispatchToProps)(Page)

export const pageQuery = graphql`
  query hitsByCategoryAndHitblock($category: String, $hitblock: String) {
    allHitsJson(
      filter: { category: { eq: $category }, hitblock: { eq: $hitblock } }
      sort: { fields: position }
    ) {
      edges {
        node {
          category
          hitblock
          slug
          title
          json
          tags
          bit_params
          lines {
            name
            account
            accounttitle
            debet_kredit
          }
        }
      }
    }
  }
`
