import React from "react"

import ContentItem from "./content-item"

const ContentList = ({ className, contentList, openInitial }) => (
  <div className={className}>
    {contentList.map(content => (
      <ContentItem
        content={content}
        openInitial={openInitial}
        key={content.title}
      />
    ))}
  </div>
)

export default ContentList
