import React, { useEffect, useLayoutEffect, createRef, useState } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"

import jsonToReact from "./json-to-react"
import Tags from "./tags"
import Lines from "./lines"
import ChevronUp from "./chevron-up"
import ChevronDown from "./chevron-down"
import PrintIcon from "./print-icon"
import EmailIcon from "./email-icon"
import CloseIcon from "./close-icon"

import { emailString } from "../mjml/share-topic"

import {
  hitToggled,
  hitOpened,
  hitVisible,
  hitNotVisible,
} from "../state/actions/hit"
import { renderforEmail } from "../state/actions/email"
import { highlightHitWord, slugFriendly, printMe } from "../../utils"
import useOnScreen from "../hooks/use-on-screen"

import {
  sizes,
  colorAliases,
  colors,
  borders,
  borderRadius,
  MOBILE_BREAKPOINT,
  boxShadow,
} from "../style/design-system"

const useStyles = createUseStyles({
  outerContainerOpen: {
    paddingBottom: sizes["md"],
    marginBottom: sizes["md"],
  },
  outerContainerClosed: {
    paddingBottom: sizes["md"],
    borderBottom: borders["regular"],
    marginBottom: sizes["md"],
  },
  title: {
    width: `100%`,
    cursor: `pointer`,
    display: `flex`,
    alignItems: `center`,
    margin: 0,
    fontWeight: 700,
    "&:hover .chevron-animate": {
      opacity: 1,
    },
  },
  openTitle: {
    marginBottom: sizes["md"],
  },
  chevron: {
    width: sizes["xl"],
    minWidth: sizes["xl"],
    opacity: 0,
    transition: `opacity 300ms ease-in-out`,
    marginLeft: sizes["md"],
  },
  titleContainer: {
    display: `flex`,
    alignItems: `center`,
  },
  openVisible: {
    marginLeft: `-${sizes["xl"]}`,
    paddingLeft: 28,
    borderLeft: borders["highlight"],
  },
  printIcon: {
    width: 30,
    height: 30,
    padding: 4,
    borderRadius: borderRadius["md"],
    transform: `translate(0, -8px)`,
    cursor: `pointer`,
    "&:hover": {
      background: colors["blue"]["100"],
    },
  },
  emailIcon: {
    width: 30,
    height: 30,
    padding: 4,
    borderRadius: borderRadius["md"],
    transform: `translate(0, -8px)`,
    cursor: `pointer`,
    "&:hover": {
      background: colors["blue"]["100"],
    },
  },
  overlay: {
    opacity: 0,
    transition: `opacity 300ms ease-out`,
  },
  showOverlay: {
    position: `fixed`,
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    background: colorAliases["overlayColor"],
    opacity: `.3`,
    zIndex: 4,
  },
  formContainer: {
    height: `90vh`,
    width: `90vw`,
    maxWidth: 800,
    background: `white`,
    position: `fixed`,
    top: `50%`,
    left: `50%`,
    transform: `translate(-50%, -50%)`,
    zIndex: 5,
    padding: `${sizes["md"]} ${sizes["xl"]}`,
    borderRadius: borderRadius["sm"],
    boxShadow: boxShadow["xl"],
  },
  closeIcon: {
    position: `absolute`,
    top: 12,
    right: 12,
    opacity: 0,
    transition: `opacity 200ms ease-out`,
    width: 40,
    height: 40,
    cursor: `pointer`,
  },
  showCloseIcon: {
    opacity: 1,
  },
  inputStyle: {
    padding: sizes["xs"],
    borderRadius: borderRadius["sm"],
    border: borders["inputBorder"],
  },
  labelStyle: {
    display: `block`,
    marginBottom: sizes["lg"],
  },
  labelTextStyle: {
    display: `block`,
    marginBottom: 2,
  },
  sendButton: {
    border: `none`,
    padding: `${sizes["xs"]} ${sizes["md"]}`,
    color: colorAliases["lightBackground"],
    cursor: `pointer`,
    boxShadow: boxShadow["md"],
    borderRadius: borderRadius["sm"],
    background: colorAliases["fancyGradient"],
  },
  [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
    printIcon: {
      display: `none`,
    },
  },
})

const ContentItem = ({
  content,
  openInitial,
  expandedHits,
  hitwords,
  toggleHit,
  openHit,
  visibleHits,
  tellImVisible,
  tellImNotVisible,
  hitToScroll,
  renderForEmail,
  dispatchRenderForEmail,
  oidcUser,
  oidcUserManager,
}) => {
  const {
    outerContainerOpen,
    outerContainerClosed,
    openTitle,
    title,
    chevron,
    titleContainer,
    printIcon,
    openVisible,
    overlay,
    showOverlay,
    formContainer,
    closeIcon,
    showCloseIcon,
    inputStyle,
    labelStyle,
    labelTextStyle,
    sendButton,
  } = useStyles()

  const [openEmailForm, setOpenEmailForm] = useState(false)
  const [emailError, setEmailError] = useState(``)
  const userName =
    oidcUser && oidcUser.profile
      ? oidcUser.profile.name ||
        `${oidcUser.profile.given_name} ${oidcUser.profile.family_name}`
      : ``
  const fromEmail = oidcUser && oidcUser.profile ? oidcUser.profile.email : ``

  const [receiverEmail, setReceiverEmail] = useState(``)
  const [subject, setSubject] = useState(`Kontohjelp: ${content.title}`)
  const [message, setMessage] = useState(
    `
    
Vennlig hilsen
${userName}
    `
  )

  const [setRef, visible] = useOnScreen({ threshold: 0.2 })
  expandedHits.includes(content.title) && visible
    ? tellImVisible(content.title)
    : tellImNotVisible(content.title)

  const containerRef = createRef()
  const emailFirstFocusRef = createRef()

  useEffect(() => {
    if (content.title === openInitial) openHit(content.title)
    if (
      hitToScroll === `` &&
      window &&
      window.decodeURI(window.location.pathname) ===
        `/${slugFriendly(content.category)}/${slugFriendly(
          content.hitblock
        )}/${slugFriendly(content.title)}`
    ) {
      containerRef.current.scrollIntoView()
      document.getElementById("main-content").scrollTop -= 35
    }
  }, [])

  useEffect(() => {
    if (hitToScroll.substring(6) === `${content.slug}`) {
      // TODO:
      // BUG: effect normally runs twice
      // runs once when route did not change
      // when route did not change, it does not actually scroll
      let timeout = window.setTimeout(() => {
        let element = document.getElementById(content.slug)
        if (element) element.scrollIntoView({ behavior: "smooth" })
      }, 200)
      // document.getElementById("main-content").scrollTop -= 35
      return () => window.clearTimeout(timeout)
    }
  }, [hitToScroll])

  useLayoutEffect(() => {
    if (emailFirstFocusRef.current) emailFirstFocusRef.current.focus()
  }, [openEmailForm])

  let open =
    expandedHits.includes(content.title) ||
    (content.title === openInitial && typeof window === `undefined`)

  const sendEmail = emailRef => {
    let body = {
      to: receiverEmail,
      subject: subject,
      content: emailString
        .replace(/\$\$message\$\$/g, message)
        .replace(/\$\$fromEmail\$\$/g, fromEmail)
        .replace(
          /\$\$content\$\$/g,
          emailRef.current.outerHTML.replace(
            /rgba\(252\, 239\, 199\, 0.87\)/g,
            `transparent`
          )
        ),
    }

    setEmailError(``)
    oidcUserManager.getUser().then(user => {
      let url = `${process.env.GATSBY_KONTOHJELP_API_URL}/SendMessage`
      fetch(url, {
        method: `POST`,
        headers: {
          "Content-Type": `application/json`,
          Authorization: `Bearer ${user.access_token}`,
        },
        body: JSON.stringify(body),
      })
        .then(response => {
          if (!response.ok) {
            throw new Error(`Epost sending feilet ${response.statusText}`)
          }
          dispatchRenderForEmail(false)
          setOpenEmailForm(false)
        })
        .catch(err => {
          setEmailError(`Sending av epost feilet, kontroller gjerne mottakers epost addresse og prøv på nytt. 
          Skulle du få samme feilmelding flere ganger ta gjerne kontakt via support.`)
          console.error(err)
        })
    })
  }

  return (
    <>
      <div
        className={
          open && visible
            ? `${outerContainerOpen} ${openVisible}`
            : open
            ? outerContainerOpen
            : outerContainerClosed
        }
        ref={containerRef}
        id={content.slug}
        style={
          renderForEmail
            ? {
                fontSize: 13,
                textAlign: `left`,
                padding: `0 30`,
                fontFamily: `Ubuntu, Helvetica, Arial, sans-serif`,
                width: `100%`,
                maxWidth: 600,
                margin: `0 auto`,
              }
            : {}
        }
      >
        <div className={titleContainer}>
          <h2
            className={open ? [title, openTitle].join(` `) : title}
            onClick={() => toggleHit(content.title)}
          >
            <span>{highlightHitWord(content.title, hitwords)}</span>

            {open && !renderForEmail ? (
              <ChevronUp
                className={chevron}
                color={colorAliases["darkColor"]}
              />
            ) : !renderForEmail ? (
              <ChevronDown
                className={chevron}
                color={colorAliases["darkColor"]}
              />
            ) : null}
          </h2>

          {open && !renderForEmail && oidcUser && (
            <EmailIcon
              className={printIcon}
              color={colors["gray"]["500"]}
              onClick={() => {
                setEmailError(``)
                dispatchRenderForEmail(true)
                setOpenEmailForm(true)
              }}
            ></EmailIcon>
          )}
          {open && !renderForEmail && oidcUser && (
            <PrintIcon
              className={printIcon}
              color={colors["gray"]["500"]}
              onClick={() => {
                printMe(containerRef)
              }}
            ></PrintIcon>
          )}
        </div>

        {open ? (
          <div ref={setRef}>
            {open && content.lines.length ? (
              <Lines
                lines={content.lines}
                bitParams={content.bit_params}
                hitwords={hitwords}
              />
            ) : null}
            {open && content.js
              ? content.js.map((child, index) =>
                  jsonToReact({
                    json: child,
                    hitwords,
                    ignoreDiblinks: false,
                    failSlug: `/${slugFriendly(
                      content.category
                    )}/${slugFriendly(content.hitblock)}/${slugFriendly(
                      content.title
                    )}`,
                    key: index,
                  })
                )
              : null}
            {open && content.tags.length ? (
              <Tags tags={content.tags} hitwords={hitwords} />
            ) : null}
          </div>
        ) : null}
        {open && (
          <div
            style={{
              borderBottom: borders["regular"],
              height: 2,
              width: `100%`,
              marginBottom: `-16px`,
              paddingTop: 16,
            }}
          ></div>
        )}
        <div
          className={openEmailForm ? [overlay, showOverlay].join(` `) : overlay}
          onClick={() => {
            dispatchRenderForEmail(false)
            setOpenEmailForm(false)
          }}
        ></div>
      </div>
      {openEmailForm && (
        <div className={formContainer} style={{ fontSize: 16 }}>
          <CloseIcon
            onClick={() => {
              dispatchRenderForEmail(false)
              setOpenEmailForm(false)
            }}
            className={`${closeIcon} ${showCloseIcon}`}
            color={colorAliases["darkColor"]}
          ></CloseIcon>
          <h3>Du kan sende "{content.title}" på epost!</h3>
          <h4 style={{ marginTop: 8 }}>
            - Fyll ut/endre feltene nedenfor og klikk send.
          </h4>
          <form
            style={{ marginTop: sizes["xl"] }}
            onSubmit={e => {
              e.preventDefault()
              sendEmail(containerRef)
            }}
          >
            <label className={labelStyle}>
              <span className={labelTextStyle}>Mottakers epost:</span>
              <input
                className={inputStyle}
                value={receiverEmail}
                onChange={e => setReceiverEmail(e.target.value)}
                type="e-mail"
                ref={emailFirstFocusRef}
              ></input>
            </label>

            <label className={labelStyle}>
              <span className={labelTextStyle}>Emne:</span>
              <input
                className={inputStyle}
                style={{ width: `100%` }}
                value={subject}
                onChange={e => setSubject(e.target.value)}
                type="text"
              ></input>
            </label>

            <label className={labelStyle}>
              <span className={labelTextStyle}>
                Personlig melding til mottaker:
              </span>
              <textarea
                className={inputStyle}
                style={{ width: `100%` }}
                cols="60"
                rows="10"
                value={message}
                onChange={e => setMessage(e.target.value)}
              ></textarea>
            </label>

            <button
              onClick={e => {
                e.preventDefault()
                sendEmail(containerRef)
              }}
              className={sendButton}
            >
              Send
            </button>
            {emailError && (
              <p
                style={{
                  marginTop: sizes["md"],
                  padding: sizes["xs"],
                  background: colors["red"]["300"],
                  borderRadius: borderRadius["sm"],
                }}
              >
                {emailError}
              </p>
            )}
          </form>
        </div>
      )}
    </>
  )
}

const mapStateToProps = state => {
  return {
    expandedHits: state.expandedHits,
    hitwords: state.hitwords,
    hitToScroll: state.hitToScroll,
    visibleHits: state.visibleHits,
    renderForEmail: state.renderForEmail,
    oidcUser: state.oidcUser,
    oidcUserManager: state.oidcUserManager,
  }
}

const mapDispatchToProps = dispatch => {
  return {
    toggleHit: hit => dispatch(hitToggled(hit)),
    openHit: hit => dispatch(hitOpened(hit)),
    tellImVisible: hit => dispatch(hitVisible(hit)),
    tellImNotVisible: hit => dispatch(hitNotVisible(hit)),
    dispatchRenderForEmail: bool => dispatch(renderforEmail(bool)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentItem)
