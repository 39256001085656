import React, { useState, useEffect, useLayoutEffect, useRef } from "react"
import { createUseStyles } from "react-jss"
import { useExchangeRates } from "../../hooks/valutakalkulator"
import { compareDates } from "../../../utils/index"

import { sizes, colors, fontSizes } from "../../style/design-system"

const useStyles = createUseStyles({
  root: {
    width: "100%",
    color: colors.gray["600"],
  },
  title: {
    fontWeight: "bolder",
    textAlign: "center",
    color: colors.gray["600"],
    margin: `${sizes["xl"]} 0 ${sizes["lg"]} 0`,
  },
  row: {
    display: "flex",
    padding: "0.2rem 0",
    alignItems: "center",
    justifyContent: "space-between",
  },
  date: {
    display: "flex",
    flex: "0 0 35%",
  },
  info: {
    flex: "0 0 65%",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
  },
  fstItem: {
    flex: "1 0 auto",
  },
  fstItemWrap: {
    flex: "1 0 auto",
    textAlign: "right",
  },
  sndItem: {
    flex: "1 0 auto",
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "nowrap",
  },
  calendarButton: {
    padding: "0",
    border: "0",
  },
})

const Info = ({
  base,
  baseAmount,
  symbol,
  symbolAmount,
  date,
  symbolData,
  round2,
  numberIsh,
}) => {
  const [isWrapped, setIsWrapped] = useState(false)
  const { rates, setBase, date: secondaryDate, setDate } = useExchangeRates(
    base,
    symbolData
  )
  const classes = useStyles()
  const lastRef = useRef(0)
  const info1Ref = useRef(0)
  const info2Ref = useRef(0)
  const equalDates = compareDates(date, new Date())

  useLayoutEffect(() => {
    if (
      (info1Ref.current &&
        lastRef.current &&
        info1Ref.current.offsetHeight !== lastRef.current.offsetHeight) ||
      (info2Ref.current &&
        lastRef.current &&
        info2Ref.current.offsetHeight !== lastRef.current.offsetHeight)
    ) {
      setIsWrapped(true)
    } else {
      setIsWrapped(false)
    }
  }, [symbolAmount])

  useEffect(() => {
    setBase(base)
  }, [setBase, base])

  return (
    <>
      {!equalDates && rates && (
        <div className={classes.root}>
          <div className={classes.title}>Historisk sammenlikning</div>

          <div className={classes.row}>
            <span className={classes.date}>
              {secondaryDate.getDate()}/{secondaryDate.getMonth() + 1}/
              {secondaryDate.getFullYear()}
            </span>
            <span className={classes.info} ref={info1Ref}>
              <div
                className={isWrapped ? classes.fstItemWrap : classes.fstItem}
              >{`${round2(Number(numberIsh(baseAmount)))} ${base}`}</div>
              <div className={classes.sndItem}>
                <div>{" = "}</div>
                {`${round2(
                  Number(rates[symbol] * numberIsh(baseAmount))
                )} ${symbol}`}
              </div>
            </span>
          </div>

          <div className={classes.row}>
            <span className={classes.date}>
              {date.getDate()}/{date.getMonth() + 1}/{date.getFullYear()}
            </span>
            <span className={classes.info} ref={info2Ref}>
              <div
                className={isWrapped ? classes.fstItemWrap : classes.fstItem}
              >{`${round2(Number(numberIsh(baseAmount)))} ${base}`}</div>
              <div className={classes.sndItem}>
                <div>{" = "}</div>
                {`${round2(Number(numberIsh(symbolAmount)))} ${symbol}`}
              </div>
            </span>
          </div>

          <div className={classes.row}>
            <span>Endring</span>
            <span ref={lastRef}>
              {round2(
                Math.abs(
                  Number(rates[symbol] * numberIsh(baseAmount)) -
                    Number(numberIsh(symbolAmount))
                )
              )}
              {` ${symbol}`}
            </span>
          </div>
        </div>
      )}
      <p
        style={{ marginTop: `2rem`, fontSize: fontSizes["sm"], width: `100%` }}
      >
        Valutakursene hentes fra Morningstar.
      </p>
    </>
  )
}

export default Info
