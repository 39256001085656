import React, { useState, useLayoutEffect, useRef } from "react"
import { connect } from "react-redux"
import { createUseStyles } from "react-jss"

import { highlightHitWord } from "../../utils"

import { sizes, fontSizes, colors } from "../style/design-system"

const useStyles = createUseStyles({
  container: {
    display: `flex`,
    "align-items": `baseline`,
  },
  content: {
    fontSize: fontSizes["sm"],
    color: colors["gray"]["400"],
  },
  ellipsis: {
    "white-space": `nowrap`,
    overflow: `hidden`,
    "text-overflow": `ellipsis`,
  },
  toggleOpenClose: {
    whiteSpace: `nowrap`,
    border: `none`,
    cursor: `pointer`,
    fontSize: fontSizes["sm"],
    color: colors["blue"]["500"],
    marginTop: sizes["xxs"],
    background: `transparent`,
  },
  dontShow: {
    visibility: `hidden`,
  },
})

const Tags = ({ tags, hitwords, renderForEmail }) => {
  const {
    container,
    content,
    ellipsis,
    toggleOpenClose,
    dontShow,
  } = useStyles()

  const [open, setOpen] = useState(false)
  const [showButton, setShowbutton] = useState(false)

  const parentRef = useRef()
  const childRef = useRef()

  useLayoutEffect(() => {
    if (parentRef.current && childRef.current) {
      const parentBox = parentRef.current.getBoundingClientRect()
      const childBox = childRef.current.getBoundingClientRect()

      const distance =
        parentBox.x + parentBox.width - (childBox.x + childBox.width)
      if (distance <= 1 && distance >= -1) setShowbutton(true)
    }
  }, [])

  const orderedTags = hitsFirst(tags, hitwords)

  const ret = renderForEmail ? null : (
    <div
      className={container}
      style={open ? { flexDirection: `column` } : null}
      ref={parentRef}
    >
      <div className={!open ? [content, ellipsis].join(` `) : content}>
        Stikkord: {highlightHitWord(orderedTags, hitwords)}
      </div>

      <button
        ref={childRef}
        className={
          showButton ? toggleOpenClose : [toggleOpenClose, dontShow].join(` `)
        }
        onClick={() => setOpen(!open)}
      >
        {!open ? `Se flere` : `Se færre`}
      </button>
    </div>
  )

  return ret
}

const mapStateToProps = state => {
  return {
    renderForEmail: state.renderForEmail,
  }
}

export default connect(mapStateToProps, null)(Tags)

const hitsFirst = (tags, hitwords) => {
  let words = tags.split(`|`).map(word => word.trim())
  let matchedCompletely = []
  let matchedPartly = []

  hitwords.forEach(word => {
    if (words.includes(word)) matchedCompletely.push(word)
  })

  let remainingWords = words.filter(word => !matchedCompletely.includes(word))

  remainingWords.forEach(word => {
    hitwords.forEach(hitword => {
      if (word.includes(hitword)) matchedPartly.push(word)
    })
  })

  remainingWords = remainingWords.filter(word => !matchedPartly.includes(word))
  return [...matchedCompletely, ...matchedPartly, ...remainingWords].join(`, `)
}
