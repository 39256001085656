import React from "react"
import { Link, useStaticQuery, graphql } from "gatsby"
import { Link as RLink } from "@reach/router"
import { createUseStyles } from "react-jss"
import { months } from "../../utils/index"

import {
  colorAliases,
  borders,
  borderRadius,
  sizes,
} from "../style/design-system"

const useStyles = createUseStyles({
  container: {
    border: borders["regularBold"],
    borderRadius: borderRadius["lg"],
  },
  header: {
    display: `flex`,
    alignItems: `baseline`,
    justifyContent: `space-between`,
    padding: sizes["md"],
    borderBottom: borders["regularBold"],
  },
  title: {
    margin: 0,
  },
  body: {
    padding: sizes["md"],
  },
  fristLine: {
    display: `flex`,
    alignItems: `baseline`,
    "& strong": {
      textTransform: `capitalize`,
    },
  },
  number: {
    padding: sizes["xs"],
    background: colorAliases["darkColor"],
    color: `white`,
    marginRight: sizes["xs"],
    width: 35,
    height: 35,
    textAlign: `center`,
    borderRadius: borderRadius["sm"],
  },
})

const fristerNavigation = [
  { text: "Vis alle frister", path: "/kontering/frister" },
  {
    text: "Abonner på frister",
    url:
      "https://calendar.google.com/calendar/ical/dibkunnskap%40gmail.com/public/basic.ics",
  },
  { text: "Fristkalender (pdf)", url: "https://www.dib.no/fristkalender/" },
]

export default ({ className }) => {
  const classes = useStyles()

  const fristerList = useStaticQuery(
    graphql`
      query FristerQuery {
        allHitsJson(filter: { hitblock: { eq: "Frister" } }) {
          edges {
            node {
              hitblock
              title
              json
              html
            }
          }
        }
      }
    `
  )

  const numberOfFrister = 7
  let monthsOrder = months
  let nodes = fristerList.allHitsJson.edges.slice().map(({ node }) => ({
    month: node.title.split(` `)[0],
    year: node.title.split(` `)[1],
    monthPosition: monthsOrder.findIndex(month =>
      node.title.toLowerCase().startsWith(month)
    ),
    frister: JSON.parse(
      node.json
    ).children[0].children[0].children[0].children.map(
      n => n.children[0].children[0].text
    ),
  }))

  let groupedByYear = nodes.reduce((accumulator, current) => {
    if (accumulator[current.year]) {
      accumulator[current.year].push(current)
    } else {
      accumulator[current.year] = []
      accumulator[current.year].push(current)
    }
    return accumulator
  }, {})

  Object.keys(groupedByYear).forEach(year => {
    groupedByYear[year].sort((a, b) => {
      if (a.monthPosition < b.monthPosition) {
        return -1
      } else {
        return 1
      }
    })
  })

  const sortedByYearAndMonth = Object.keys(groupedByYear)
    .map(year => parseInt(year, 10))
    .sort()
    .reduce((accumulator, current) => {
      accumulator.push(groupedByYear[current])
      return accumulator
    }, [])
    .flat()
    .map(grouping => {
      let ret = []
      if (grouping && grouping.frister) {
        grouping.frister.forEach(frist => {
          const year = parseInt(grouping.year, 10)
          const month = grouping.monthPosition
          const monthString = grouping.month
          const date = parseInt(frist.split(`.`)[0], 10)
          const text = frist.substring(frist.indexOf("-") + 1).trim()
          ret.push({
            year,
            month,
            monthString,
            date,
            text,
            dateObj: new Date(year, month, date),
          })
        })
      }
      return ret
    })
    .flat()

  let now = new Date()

  let start = sortedByYearAndMonth.findIndex(x => {
    return now < x.dateObj
  })

  const nextFrister = sortedByYearAndMonth.slice(start, start + numberOfFrister)

  return (
    <div className={[className, classes.container].join(` `)}>
      <div className={classes.header}>
        <h3 className={classes.title}>Frister</h3>
        <div style={{ color: colorAliases["linkColor"] }}>
          {fristerNavigation.map((item, index) => (
            <React.Fragment key={index}>
              {item.path && (
                <RLink style={{ textDecoration: `none` }} to={item.path}>
                  {item.text}
                </RLink>
              )}
              {item.url && (
                <a
                  style={{ textDecoration: `none` }}
                  href={item.url}
                  target="_blank"
                  rel={`noopener noreferrer`}
                  data-iconafter="no"
                >
                  {item.text}
                </a>
              )}
              {index !== fristerNavigation.length - 1 && <> | </>}
            </React.Fragment>
          ))}
        </div>
      </div>
      <div className={classes.body}>
        {nextFrister.map((frist, index) => (
          <div className={classes.fristLine} key={index}>
            <div className={classes.number}>{frist.date}</div>{" "}
            <strong>{frist.monthString}</strong> <p> - {frist.text}</p>
          </div>
        ))}
      </div>
    </div>
  )
}
