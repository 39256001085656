import React, { createRef } from "react"
import Header from "./Header"
import Calculator from "./Calculator"
import PrintIcon from "../../components/print-icon"
import { createUseStyles } from "react-jss"

import {
  sizes,
  fontSizes,
  borderRadius,
  colors,
  MOBILE_BREAKPOINT,
} from "../../style/design-system"
import { printMe } from "../../../utils"

const useStyles = createUseStyles({
  root: {
    margin: "0 auto",
    display: "flex",
    flexFlow: "column",
    alignItems: "center",
    width: "90%",
    maxWidth: 400,
    "@media(min-width: 45rem)": {
      width: sizes["8xl"],
    },
  },
  printIconContainer: {
    width: `100%`,
  },
  printIcon: {
    width: 30,
    height: 30,
    padding: 4,
    borderRadius: borderRadius["md"],
    transform: `translate(0, -8px)`,
    cursor: `pointer`,
    "&:hover": {
      background: colors["blue"]["100"],
    },
  },
  [`@media (max-width: ${MOBILE_BREAKPOINT}px)`]: {
    printIcon: {
      display: `none`,
    },
  },
})

const Valutakalkulator = () => {
  const ref = createRef()
  const classes = useStyles()
  return (
    <div className={classes.root} ref={ref}>
      <Header
        fontSize={fontSizes["3xl"]}
        subHeader="med historiske valutakurser"
      >
        Valutakalkulator
      </Header>
      <Calculator />
      <div className={classes.printIconContainer}>
        <PrintIcon
          className={classes.printIcon}
          color={colors["gray"]["500"]}
          onClick={() => {
            printMe(ref)
          }}
        ></PrintIcon>
      </div>
    </div>
  )
}

export default Valutakalkulator
