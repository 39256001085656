import React from "react"
import { createUseStyles } from "react-jss"

import { sizes, colors, fontSizes } from "../../style/design-system"

const useStyles = createUseStyles({
  root: {
    display: "flex",
    justifyContent: "flex-end",
    width: "100%",
  },
  rate: {
    marginTop: "0",
    padding: sizes["xxs"],
    fontSize: fontSizes["sm"],
    color: colors.gray["500"],
    fontWeight: "400",
    flexGrow: "0",
  },
})

const RateInfo = ({ rate }) => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <h3 className={classes.rate}>Kurs: {rate && rate.toFixed(4)}</h3>
    </div>
  )
}

export default RateInfo
