import React, { useState, useLayoutEffect, useRef } from "react"
import { createUseStyles } from "react-jss"
import Input from "./input"
import Select from "./select"

import {
  borderRadius,
  colors,
  sizes,
  fontSizes,
  boxShadow,
} from "../../style/design-system"

const useStyles = createUseStyles({
  title: {
    fontSize: fontSizes["3xl"],
    textAlign: `center`,
  },
  root: {
    width: "90%",
    display: `flex`,
    flexDirection: `column`,
    alignItems: `flex-end`,
    margin: `${sizes["lg"]} auto 0 auto`,
  },
  fieldContainer: {
    width: `100%`,
    display: `flex`,
    alignItems: `flex-end`,
    "& strong": {
      marginBottom: 26,
      marginRight: 10,
      fontSize: 34,
    },
  },
  field: {
    display: "flex",
    flexDirection: "column",
    marginBottom: sizes["lg"],
    "& label": {
      textAlign: `right`,
      marginBottom: sizes["xxs"],
      color: colors["gray"]["500"],
    },
    width: `100%`,
  },
  inputGroup: {
    display: `flex`,
    alignItems: `center`,
    height: `40px`,
    borderRadius: borderRadius["sm"],
    boxShadow: boxShadow["md"],
    border: `1px solid ${colors.blue["500"]}`,
  },
})

const MvaCalculator = () => {
  const focusRef = useRef()
  useLayoutEffect(() => {
    if (focusRef && focusRef.current) {
      focusRef.current.focus()
    }
  }, [focusRef, focusRef.current])

  const [input1, setInput1] = useState(0)
  const [input2, setInput2] = useState(0)
  const [input3, setInput3] = useState(0)
  const [mva, setMva] = useState(25)
  const classes = useStyles()

  const handleInput1Change = value => {
    setInput1(value)
    const calcMva = (value * mva) / 100
    setInput2(calcMva)
    setInput3(value + calcMva)
  }

  const handleInput2Change = value => {
    setInput2(value)
    const calcAmount = value * (100 / mva)
    setInput1(calcAmount)
    setInput3(calcAmount + value)
  }

  const handleInput3Change = value => {
    setInput3(value)
    const calcMva = value * (mva / (100 + mva))
    setInput2(calcMva)
    setInput1(value - calcMva)
  }

  const handleMvaChange = value => {
    setMva(value)
    const calcMva = (input1 * value) / 100
    setInput2(calcMva)
    setInput3(input1 + calcMva)
  }

  return (
    <>
      <div>
        <h2 className={classes.title}>MVA-Kalkulator</h2>
      </div>
      <div className={classes.root}>
        <div className={classes.field} style={{ width: `calc(100% - 28px)` }}>
          <label>Beløp eks. mva</label>
          <div className={classes.inputGroup}>
            <Input
              value={input1}
              onChange={handleInput1Change}
              ref={focusRef}
            />
          </div>
        </div>

        <div className={classes.fieldContainer}>
          <strong>+</strong>
          <div className={classes.field}>
            <label>Mva</label>
            <div className={classes.inputGroup}>
              <Select value={mva} onChange={handleMvaChange} />
              <Input value={input2} onChange={handleInput2Change} />
            </div>
          </div>
        </div>

        <div className={classes.fieldContainer}>
          <strong>=</strong>
          <div className={classes.field}>
            <label>Beløp inkl. mva</label>
            <div className={classes.inputGroup}>
              <Input value={input3} onChange={handleInput3Change} />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default MvaCalculator
